<template>
	<div style='margin:10px;padding:20px 20px 0 20px;'>
		<div class='title' style=' padding-top:20px;background-color:#f3f3f3'>
			<el-form :inline="true" style="display:flex;flex-wrap:wrap" label-width="80px" ref='searchlist'>
				<el-form-item label="类型名称">
					<el-input v-model="typeName" clearable placeholder="请输入类型名称"></el-input>
				</el-form-item>
				<el-form-item label="标签类型" prop="type">
					<el-select v-model="labelType" placeholder="请选择标签类型">
						<el-option label="人员标签" :value="1"></el-option>
						<el-option label="房屋标签" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="deviceUpateDialog('add')"><i
							class="el-icon-circle-plus-outline"></i> 新增类型</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div>
			<el-table v-loading="loading" :data="tableData.list" style="width: 100%;margin-top:20px" stripe>
				<el-table-column show-overflow-tooltip align="center" prop="type_name" label="类型名称">
				</el-table-column>
				<el-table-column show-overflow-tooltip align="center" prop="type" label="标签类型">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">人员标签</span>
						<span v-if="scope.row.type == 2">房屋标签</span>
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip align="center" prop="remarks" label="备注">
				</el-table-column>
				<el-table-column show-overflow-tooltip align="center" width="200px" label="操作">
					<template slot-scope="scope">
						<el-button type="primary" @click="deviceUpateDialog('edit', scope.row)">编辑</el-button>
						<el-button type="danger" @click="deleteDevice(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
				 :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="tableData.totalRow">
				</el-pagination>
			</div>
		</div>
		

		<!-- 新增、编辑弹框 -->
		<el-dialog :close-on-click-modal='false' :title="updateType=='add'?'新增':'修改'" :visible.sync="dialogFormVisible" width="700px"
			@closed="colsedDialog('form')">
			<el-form :model="form" ref="form" class="formbox" :rules="rules" :label-width="formLabelWidth">
				<el-form-item label="类型名称" prop="typeName">
					<el-input v-model="form.typeName"></el-input>
				</el-form-item>
				<el-form-item label="标签类型" prop="type">
					<el-select v-model="form.type" placeholder="请选择标签类型">
						<el-option label="人员标签" :value="1"></el-option>
						<el-option label="房屋标签" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="子标签是否互斥" prop="flag">
					<el-select v-model="form.flag" placeholder="请选择子标签是否互斥">
						<el-option label="是" :value="1"></el-option>
						<el-option label="否" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" :rows="2" v-model="form.remarks"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="submitLoading" @click="submitForm('form')">确 定</el-button>
					<el-button @click="resetForm('form')">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		QueryList,
		Add,
		Update,
		Delete
	} from '@/api/tag'
	export default {
		name: "",
		props: {},
		data() {
			return {
				typeName:'',
				labelType: null,
				common:'',
				token: '',
				devicename: '',
				loading: false,
				tableData: {
					list:[],
					totalRow:0
				},
				form: {
					id: '',
					typeName: '',
					remarks: '',
					type: null,
					flag: null
				},
				fenye: {
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10,
					totalnum: 9
				},
				currentPage:1,
				updateType: 'add',
				dialogFormVisible: false,
				formLabelWidth: '140px',
				submitLoading: false,
				rules: {
					typeName: [{
						required: true,
						message: '请输入类型名称',
						trigger: 'blur'
					}], type: [{
						required: true,
						message: '请选择标签类型',
						trigger: 'blur'
					}],flag: [{
						required: true,
						message: '请选择子标签是否互斥',
						trigger: 'blur'
					}]					
				}
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();

		},
		computed: {},
		methods: {
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//查询
			search() {
				this.getList();
			},
			//获取设备列表
			getList() {
				this.loading = true;
				QueryList({
					userid: this.GLOBAL.adminId(),
					typeName: this.typeName,
					type: this.labelType,
					page:this.currentPage,
					pageSize:this.fenye.pagesize
				}).then((res) => {
					this.loading = false;
					if (res.result == 200) {
						console.log(res);
						this.tableData = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//打开编辑弹框
			deviceUpateDialog(type, row) {
				this.updateType = type;
				this.dialogFormVisible = true;
				this.form.id = '';
				if (type == 'edit') {
					this.form = {
						id: row.id,
						typeName: row.type_name,
						remarks: row.remarks,
						flag: row.flag,
						type: row.type
					}
				}else{
					this.form = {
						id: '',
						typeName: '',
						remarks: '',
						flag: null,
						type: null
					}
				}
			},
			//关闭编辑弹框
			colsedDialog(formName) {
				this.resetForm(formName);
			},
			//提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let params = {
							userid: this.GLOBAL.adminId(),
							id: this.form.id,
							typeName: this.form.typeName,
							remarks: this.form.remarks,
							flag: this.form.flag,
							type: this.form.type
						}
						this.submitLoading = true;
						if (this.updateType == 'add') {
							Add(params).then((res) => {
								this.submitLoading = false;
								if (res.result == 200) {
									this.$message.success('新增成功');
									this.getList();
									this.dialogFormVisible = false
								} else {
									this.$message.error(res.description);
								}
							})
						} else {
							Update(params).then((res) => {
								this.submitLoading = false;
								if (res.result == 200) {
									this.$message.success('更新成功');
									this.getList();
									this.dialogFormVisible = false
								} else {
									this.$message.error(res.description);
								}
							})
						}

					} else {
						return false;
					}
				});
			},
			//重置表单
			resetForm(formName) {
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.dialogFormVisible = false;
				}
			},
			//删除
			deleteDevice(row) {
				this.$confirm('是否确认删除该类型?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					Delete({
						userid: this.GLOBAL.adminId(),
						id: row.id
					}).then((res) => {
						if (res.result == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getList();
						} else {
							this.$message.error(res.description);
						}
					})

				}).catch(() => {});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.formbox {
		width: 90%;
	}
	.block{
		padding: 30px 0;
	}
</style>
