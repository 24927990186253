import request from '@/utils/request'

export function QueryList(data) {
	return request({
		url: '/personLabel/labelPage',
		method: 'get',
		params: data
	})
}
export function Add(data) {
	return request({
		url: '/personLabel/addLabel',
		method: 'post',
		data: data
	})
}
//修改
export function Update(data) {
	return request({
		url: '/personLabel/editLabel',
		method: 'put',
		data: data
	})
}
//删除
export function Delete(data) {
	return request({
		url: '/personLabel/deleteLabel',
		method: 'DELETE',
		params: data
	})
}
export function labelItemPage(data) {
	return request({
		url: '/personLabel/labelItemPage',
		method: 'get',
		params: data
	})
}
export function labelTree(data) {
	return request({
		url: '/personLabel/labelTree',
		method: 'get',
		params: data
	})
}
//新增
export function addLabelItem(data) {
	return request({
		url: '/personLabel/addLabelItem',
		method: 'post',
		data: data
	})
}
//修改
export function editLabelItem(data) {
	return request({
		url: '/personLabel/editLabelItem',
		method: 'put',
		data: data
	})
}
//删除
export function deleteLabelItem(data) {
	return request({
		url: '/personLabel/deleteLabelItem',
		method: 'DELETE',
		params: data
	})
}